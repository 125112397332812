import { defineStore } from 'pinia';

export const useRootStore = defineStore('root', () => {
  const isShowMainHeader = ref(true);
  const isHeaderBandDisplayed = ref(true);

  function setIsShowMainHeader(isShow: boolean) {
    isShowMainHeader.value = isShow;
  }
  function setIsHeaderBandDisplayed(isDisplayed: boolean) {
    isHeaderBandDisplayed.value = isDisplayed;
  }

  return {
    isShowMainHeader,
    isHeaderBandDisplayed,
    setIsHeaderBandDisplayed,
    setIsShowMainHeader
  };
});
